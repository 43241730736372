<template>
  <form
    class="flex flex-col gap-6"
    @submit.prevent="onSubmit"
  >
    <PortollInput
      id="email"
      v-model="form.email"
      type="text"
      autocomplete="off"
      placeholder="Email"
      icon="material-symbols:mail"
      :errors="errors.email"
    />

    <PortollInput
      id="password"
      v-model="form.password"
      type="password"
      autocomplete="off"
      placeholder="Password"
      icon="material-symbols:lock"
      :errors="errors.password"
    />

    <div class="flex items-center justify-between">
      <NuxtLink
        id="forgot-password-link"
        to="/auth/forgot-password"
        class="text-sm text-slate-600 hover:underline focus:outline-none focus:underline"
        tabindex="2"
      >
        Forgot Password
      </NuxtLink>

      <PortollButton tabindex="1">
        Sign In
      </PortollButton>
    </div>
  </form>
</template>

<script lang="ts" setup>
  import {useAuthStore} from '~/stores/useAuthStore';

  const auth = useAuthStore();

  const form = ref({
    email: '',
    password: '',
  });
  const errors: any = ref({});

  async function onSubmit() {
    try {
      await auth.login(form.value);

      errors.value = {};
      form.value.email = '';
      form.value.password = '';
    } catch (error: any) {

      errors.value = error.data.errors;
    }
  }
</script>
